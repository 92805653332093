import React, { useState } from 'react';
import Modal from 'react-modal';
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { useInView } from 'react-intersection-observer';

import LogoImage from "../../images/LOGO_L.png";
import { ReactComponent as InstagramIcon } from "images/instagram-icon.svg";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as LinkedinIcon } from "images/linkedin-icon.svg";

const Container = tw.div`relative bg-teal-800 text-gray-100  -mx-8 -mb-8 px-8`;
const Content = tw.div`max-w-screen-xl mx-auto pt-16 pb-8`

const FiveColumns = tw.div`flex flex-wrap justify-between`;

const Column = tw.div`w-1/2 md:w-1/5 mb-8 md:mb-0 text-sm sm:text-base text-center md:text-left`;
const CompanyColumn = tw.div`text-center md:text-left mb-16 lg:mb-0 w-full lg:w-1/5`;

const ColumnHeading = tw.h5`font-bold uppercase`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-100 pb-1 transition duration-300`;

const FooterLink = styled.a`
  ${tw`text-gray-100 hover:text-gray-300 transition duration-300`}
  &:hover {
    color: inherit;
    text-decoration: none;
  }
`;

const LogoContainer = tw.div`flex items-center justify-center lg:justify-start`;
const LogoImg = tw.img`w-56`;
const LogoText = tw.h5`ml-2 text-xl font-black`;

const CompanyAddress = tw.address`mt-4 max-w-xs font-medium text-sm mx-auto lg:mx-0 lg:mr-4 leading-loose text-center lg:text-left not-italic`;

const SocialLinksContainer = tw.div`mt-4 text-center lg:text-left`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-500 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;


const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.5)',
  zIndex: 999,
};

const modalContentStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '80%',
  maxHeight: '80%',
  width: 'auto',
  padding: '20px',
  backgroundColor: 'white',
  borderRadius: '10px',
  overflowY: 'auto',
  zIndex: 1000,
};

const closeButtonStyle = {
  background: 'none',
  border: 'none',
  fontSize: '1.5em',
  cursor: 'pointer',
  float: 'right',
  color: '#333',
};


// Styled components for typography
const ContentHeading = styled.h2`
  ${tw`text-2xl font-bold mb-4 text-primary-500`}
`;

const Subheading = styled.h3`
  ${tw`text-lg font-semibold mb-2 text-gray-600`}
`;

const Paragraph = styled.p`
  ${tw`text-sm text-gray-800 mb-4`}
`;

const ModalContent = styled.div`
  ${tw`bg-white rounded-lg p-8 max-w-lg w-full relative`}
  max-height: 80vh; // Limit the height of the modal content
  overflow-y: auto; // Enable scrolling within the modal
`;

const CloseButton = styled.button`
  ${tw`absolute top-0 right-0 mt-4 mr-4 bg-gray-700 text-white p-2 rounded`}
  z-index: 10; // Ensure the close button is always visible
`;

// Custom styles for lists and links inside the modal
const listStyle = {
  listStyleType: 'disc',
  paddingLeft: '20px',
  marginBottom: '10px',
};

const linkStyle = {
  color: '#1a73e8', // or any other color you prefer
  textDecoration: 'underline',
  cursor: 'pointer',
};

const LegalLinks = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  // Function to open the modal and set the content
  const openModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Function to handle clicks outside the modal content
  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();  // Close modal if click is on background overlay
    }
  };

  const legalNoticeContent = (
    <>
      <ContentHeading>Legal Notice</ContentHeading>
      <Subheading>1. General Information</Subheading>
      <Paragraph> <ul style={listStyle}>
          <li><strong>Company Name:</strong> LockMe </li>
          <li><strong>Registered Address:</strong> Carrer de Buixeda 119, 08203 Sabadell, Barcelona, Spain </li>
          <li><strong>Tax Identification Number (NIF):</strong> ESB67576983 </li>
          <li><strong>Contact Email:</strong> <a href="mailto:i.xirau@lock-me.com" style={linkStyle}>info@LockMe.es</a> </li>
          <li><strong>Website:</strong> <a href="http://www.lock-me.com" style={linkStyle}>www.lock-me.com</a> </li>
        </ul>
      </Paragraph> 

      <Subheading>2. Intellectual Property Rights</Subheading>
      <Paragraph>All content on this website, including text, images, graphics, and logos, is the property of LockMe and is protected by intellectual property laws. Unauthorized reproduction or distribution of this content is strictly prohibited.</Paragraph>
      
      <Subheading>3. Limitations of Liability</Subheading>
      <Paragraph>LockMe is not responsible for any damages resulting from the use or inability to use the information provided on this website. The information is provided "as is," without warranty of any kind, either express or implied.</Paragraph>
      
      <Subheading>4. Third-Party Links</Subheading>
      <Paragraph>Our website may contain links to third-party sites. LockMe has no control over the content of these sites and is not responsible for their practices. We encourage you to review the privacy policies and terms of service of any third-party sites you visit.</Paragraph>
    
      <Subheading>5. Governing Law</Subheading>
      <Paragraph>This legal notice is governed by the laws of Spain. Any disputes arising from this legal notice shall be resolved in the courts of Barcelona.</Paragraph>
    </>
  );

  const privacyPolicyContent = (
    <>
      <ContentHeading>Privacy Policy</ContentHeading>
      <Subheading>1. Introduction</Subheading>
      <Paragraph>LockMe is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website <a href="http://www.lock-me.com">http://www.lock-me.com</a>. Please read this policy carefully to understand our views and practices regarding your personal data and how we will treat it.</Paragraph>
      
      <Subheading>2. Information We Collect</Subheading>
      <Paragraph>
        <ul style={listStyle}>
          <li><strong>Personal Data:</strong> Information that can identify you, such as your name, email address, phone number, and billing address.</li>
          <li><strong>Usage Data:</strong> Information about how you use our website and services, including your IP address, browser type, and pages visited.</li>
        </ul>
      </Paragraph>

      <Subheading>3. Use of Your Information</Subheading>
      <Paragraph>We may use the information we collect from you for various purposes, including:
        <ul style={listStyle}>
          <li>To provide, maintain, and improve our website and services.</li>
          <li>To process transactions and manage your orders.</li>
          <li>To communicate with you, including responding to your inquiries and providing customer support.</li>
          <li>To send you marketing communications, if you have opted in to receive them.</li>
        </ul>
      </Paragraph>

      <Subheading>4. Data Sharing and Disclosure</Subheading>
      <Paragraph>We do not sell, trade, or otherwise transfer your personal information to outside parties, except in the following circumstances:
        <ul style={listStyle}>
          <li>With your consent.</li>
          <li>To comply with legal obligations.</li>
          <li>To protect our rights, privacy, safety, or property, and/or that of our affiliates, you, or others.</li>
        </ul>
      </Paragraph>

      <Subheading>5. Data Security</Subheading>
      <Paragraph>We take reasonable measures to protect your personal information from loss, theft, misuse, and unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the Internet or method of electronic storage is 100% secure. </Paragraph>

      <Subheading>6. Your Rights</Subheading>
      <Paragraph>Depending on your location, you may have the following rights regarding your personal data:
        <ul style={listStyle}>
          <li>The right to access your personal data.</li>
          <li>The right to rectify inaccurate data.</li>
          <li>The right to request the deletion of your data.</li>
          <li>The right to withdraw consent at any time, where we rely on consent to process your personal data.</li>
        </ul>
      </Paragraph>

      <Subheading>7. Cookies</Subheading>
      <Paragraph>Our website uses cookies to enhance user experience. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can modify your browser setting to decline cookies if you prefer. </Paragraph>
      
      <Subheading>8. Changes to This Privacy Policy</Subheading>
      <Paragraph>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. </Paragraph>

      <Subheading>9. Contact Us</Subheading>
      <Paragraph>If you have any questions or concerns about this Privacy Policy, please contact us at:
        <p><strong>Email:</strong> <a href="mailto:i.xirau@lock-me.com" style={linkStyle}>info@lock-me.com</a><br />
        <strong>Address:</strong> Carrer de Buixeda 119, 08203 Sabadell, Barcelona, Spain</p>
      </Paragraph>

    </>
  );

  const termsOfServiceContent = (
    <>
      <ContentHeading>Terms of Service</ContentHeading>

      <Subheading>1. Acceptance of Terms</Subheading>
      <Paragraph>By accessing or using our website and services, you agree to comply with these Terms of Service. If you do not agree with any part of these terms, you must not use our website.</Paragraph>
      
      <Subheading>2. User Responsibilities</Subheading>
      <Paragraph>You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to provide accurate information during the registration process and to update your information as necessary.</Paragraph>

      <Subheading>3. Service Availability</Subheading>
      <Paragraph>LockMe reserves the right to modify or discontinue our services at any time without notice. We will not be liable for any modification or discontinuance of the services.</Paragraph>

      <Subheading>4. Prohibited Activities</Subheading>
      <Paragraph>You agree not to engage in any of the following prohibited activities:
        <ul style={listStyle}>
          <li>Using the website for any illegal purpose or in violation of any local, state, national, or international law.</li>
          <li>Attempting to gain unauthorized access to our systems or networks.</li>
          <li>Interfering with the security of our website or the performance of our services.</li>
        </ul>
      </Paragraph>

      <Subheading>5. Modification of Terms</Subheading>
      <Paragraph>LockMe reserves the right to modify these Terms of Service at any time. We will notify you of significant changes by posting the new terms on our website. Your continued use of the services after such changes constitutes your acceptance of the new terms.</Paragraph>
      
      <Subheading>6. Governing Law</Subheading>
      <Paragraph>These terms shall be governed by the laws of Spain. Any disputes arising under or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of Barcelona.</Paragraph>

      <Subheading>7. Contact Information</Subheading>
      <Paragraph>For any questions about these Terms of Service, please contact us at:
        <p><strong>Email:</strong> <a href="mailto:i.xirau@lock-me.com" style={linkStyle}>info@lock-me.com</a><br />
        <strong>Address:</strong> Carrer de Buixeda 119, 08203 Sabadell, Barcelona, Spain</p>
      </Paragraph>

    </>
  );

  const cookiesPolicyContent = (
    <>
      <ContentHeading>Cookie Policy</ContentHeading>

      <Paragraph>Last updated: 01/10/2024</Paragraph>

      <Subheading>What Are Cookies</Subheading>
      <Paragraph>As is common practice with almost all professional websites, this site uses cookies, which are tiny files that are downloaded to your computer, to improve your experience. This page describes what information they gather, how we use it, and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from being stored; however, this may downgrade or 'break' certain elements of the site’s functionality.</Paragraph>
      
      <Subheading>How We Use Cookies</Subheading>
      <Paragraph>We use cookies for various reasons detailed below. Unfortunately, in most cases, there are no industry-standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or not, in case they are used to provide a service that you use.</Paragraph>

      <Subheading>Disabling Cookies</Subheading>
      <Paragraph>You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of this site. Therefore, it is recommended that you do not disable cookies.</Paragraph>
      
      <Subheading>The Cookies We Set</Subheading>
      <Paragraph>
        <ul style={listStyle}>
          <li><strong>Account-related cookies</strong>: If you create an account with us, we will use cookies for the management of the signup process and general administration. These cookies will usually be deleted when you log out; however, in some cases, they may remain afterward to remember your site preferences when logged out.</li>
          <li><strong>Login-related cookies</strong>: We use cookies when you are logged in so that we can remember this fact. This prevents you from having to log in every single time you visit a new page. These cookies are typically removed or cleared when you log out to ensure that you can only access restricted features and areas when logged in.</li>
        </ul>
      </Paragraph>

      <Subheading>Third-Party Cookies</Subheading>
      <Paragraph>In some special cases, we also use cookies provided by trusted third parties. The following section details which third-party cookies you might encounter through this site.
        <ul style={listStyle}>
          <li><strong>Google Analytics</strong>: This site uses Google Analytics, which is one of the most widespread and trusted analytics solutions on the web for helping us understand how you use the site and how we can improve your experience. These cookies may track things such as how long you spend on the site and the pages that you visit so we can continue to produce engaging content.         </li>
        </ul>
      </Paragraph>

      <Subheading>Cookie Settings</Subheading>
      <Paragraph>During your navigation on the Website, Users have the option to allow, block, or delete cookies installed on their devices through the browser settings. Instructions for configuring cookie usage in various browsers can be found through the following links:
        <ul style={listStyle}>
          <li><a href="https://support.google.com/chrome/answer/95647?hl=es" style={linkStyle}>Chrome</a></li>
          <li><a href="https://support.apple.com/kb/ph21411?locale=es_ES" style={linkStyle}>Safari</a></li>
          <li><a href="https://support.mozilla.org/es/kb/Borrar%20cookies" style={linkStyle}>Firefox</a></li>
          <li><a href="http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-11" style={linkStyle}>Internet Explorer</a></li>
        </ul>
        If you are using another browser, you can obtain information on how to configure cookie usage on your device through the help section of that browser. If you need assistance configuring cookies in that browser, you can email us at <a href="mailto:i.xirau@lock-me.com">support@lock-me.com</a> for us to contact you as soon as possible.
      </Paragraph>

      <Subheading>Changes</Subheading>
      <Paragraph>When deemed appropriate, LockMe may modify this policy, and it is the user's responsibility to periodically consult the current policy.</Paragraph>
      
      <Subheading>More Information</Subheading>
      <Paragraph>If you are looking for more information, you can contact us through our contact page or by emailing us at <a href="i.xirau@lock-me.com">support@lock-me.com</a></Paragraph>

    </>
  );

  return (
    <>
      <LinkList>
      
          <LinkListItem> <a href="#!" onClick={() => openModal(legalNoticeContent)}>Legal Notice</a></LinkListItem>
        
          <LinkListItem> <a href="#!" onClick={() => openModal(privacyPolicyContent)}>Privacy Policy</a></LinkListItem>
       
          <LinkListItem> <a href="#!" onClick={() => openModal(termsOfServiceContent)}>Terms of Service</a></LinkListItem>
       
          <LinkListItem> <a href="#!" onClick={() => openModal(cookiesPolicyContent)}>Cookies Policy</a></LinkListItem>
        
      </LinkList>

      {isModalOpen && (
        <div style={modalOverlayStyle} onClick={handleBackgroundClick}>
          <div style={modalContentStyle}>
            <button style={closeButtonStyle} onClick={closeModal}>
              &times;
            </button>
            {/* Render the JSX content */}
            {modalContent}
          </div>
        </div>
      )}
    </>
  );
};

const CopyrightAndCompanyInfoRow = tw.div`pb-0 text-sm font-normal flex flex-col sm:flex-row justify-between items-center`
const CopyrightNotice = tw.div``
const CompanyInfo = tw.div``

const Divider = tw.div`my-8 border-b-2 border-gray-800`;

export default () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <Container ref={ref}>
      <Content inView={inView}>
        <FiveColumns>
          <CompanyColumn>
            <LogoContainer>
              <LogoImg src={LogoImage} alt="LockMe Logo" />
              <LogoText></LogoText>
            </LogoContainer>
            <CompanyAddress>
              Carrer de Buixeda 119<br />
              08203 Sabadell, Barcelona
            </CompanyAddress>
            <SocialLinksContainer>
              <SocialLink href="https://www.instagram.com/mylock.es/?hl=es" target='_blank' aria-label="Instagram">
                <InstagramIcon />
              </SocialLink>
              <SocialLink href="https://twitter.com/mylock_es" target='_blank' aria-label="Twitter">
                <TwitterIcon />
              </SocialLink>
              <SocialLink href="https://www.linkedin.com/company/lock-me/" target='_blank' aria-label="LinkedIn">
                <LinkedinIcon />
              </SocialLink>
            </SocialLinksContainer>
          </CompanyColumn>
          <Column>
          </Column>
          <Column>
          </Column>
          
          <Column>
            <ColumnHeading>Legal</ColumnHeading>
            <LegalLinks />
          </Column>

          <Column>
            <ColumnHeading>Contact</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <FooterLink href="tel:+34633874960" aria-label="Call LockMe">+34 633 874 960</FooterLink>
              </LinkListItem>
              <LinkListItem>
                <FooterLink href="mailto:info@lock-me.com" aria-label="Email LockMe">info@lock-me.com</FooterLink>
              </LinkListItem>
              <LinkListItem>
                <FooterLink href="https://mylock.es" aria-label="Visit LockMe B2B Site">LockMe B2B Lockers</FooterLink>
              </LinkListItem>
            </LinkList>
          </Column>
        </FiveColumns>
        <Divider />
        <CopyrightAndCompanyInfoRow>
          <CopyrightNotice>&copy; Copyright 2024, LOCKME ACCESS S.L.</CopyrightNotice>
          <CompanyInfo> <a href="https://www.lock-me.com" aria-label="Visit LockMe Site"> CONVERSATIONAL SMART LOCKER SOLUTIONS </a></CompanyInfo>
        </CopyrightAndCompanyInfoRow>
      </Content>
    </Container>
  );
};
